<template>
  <v-navigation-drawer
    app
    left
    :color="sideMenuPermant ? 'white' : 'background'"
    width="200"
    touchless
    :floating="!sideMenuPermant"
    permanent
    :mini-variant="sideMenuPermant"
  >

    <!-- Logo -->
    <template slot="prepend">
      <v-container fluid class="hidden-md-and-down">
        <v-row>
          <v-col cols="12" class="text-center">
            <v-avatar tile size="80">
              <v-img
                contain
                aspect-ratio="2"
                src="../assets/atlas-logo.png"
              ></v-img>
            </v-avatar>
          </v-col>
        </v-row>

        <!-- Organization Name -->
        <v-row v-if="user != null && user.user_type_id == userTypes.PARTICIPANT && user.participant" >
          <v-col cols="12" class="text-center">
            <strong>{{ user.participant.organization }}</strong>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Main Options -->
    <template slot="default">

      <!-- ADMINISTRATOR -->
      <v-list v-if="user != null && user.user_type_id == userTypes.ADMINISTRATOR">
        <!-- Services -->
        <v-list-item :to="'/'" color="secondary">
          <v-list-item-avatar size="30" tile>
            <v-img v-bind:src="getImgUrl('apps')"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="$t('services')"
              class="font-weight-medium btn_text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item> 

        <!-- Participants -->
        <v-list-item :to="'/app/participants'" color="secondary">
          <v-list-item-avatar size="30" tile>
            <v-img v-bind:src="getImgUrl('vendors')"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="$t('vendors')"
              class="font-weight-medium btn_text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item> 
      </v-list>

      <!-- PARTICIPANT -->
      <v-list v-if="user != null && user.user_type_id == userTypes.PARTICIPANT && user.participant">
        <!-- Services -->
        <v-list-item 
          v-if="user.participant != null && user.participant.participant_status_id == participantStatuses.APPROVED"
          :to="'/'" 
          color="secondary"
        >
          <v-list-item-avatar size="30" tile>
            <v-img v-bind:src="getImgUrl('apps')"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="$t('services')"
              class="font-weight-medium btn_text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Users -->
        <v-list-item 
          v-if="user.participant.user_role_id == participantUserTypes.ADMINISTRATOR && user.participant.participant_status_id == participantStatuses.APPROVED"
          :to="'/app/users'" color="secondary" 
        >
          <v-list-item-avatar size="30" tile>
            <v-img v-bind:src="getImgUrl('vendors')"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="$t('users')"
              class="font-weight-medium btn_text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <!-- Organization -->
        <v-list-item 
          v-if="user.participant.user_role_id == participantUserTypes.ADMINISTRATOR"
          :to="'/app/settings'" color="secondary" 
        >
          <v-list-item-avatar size="30" tile>
            <v-img v-bind:src="getImgUrl('settings')"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="$t('settings')"
              class="font-weight-medium btn_text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>        

        <v-divider
          style="background-color: #eb7e61"
          class="mt-5 mx-8"
        ></v-divider>
      </v-list>

    </template>

    <!-- Bottom Options -->
    <template slot="append">

      <!-- Create Service -->
      <v-list v-if="user != null && user.user_type_id == userTypes.PARTICIPANT && user.participant && user.participant.participant_status_id == participantStatuses.APPROVED">
        <v-list-item
          color="secondary"
          :to="{ name: 'GenerateService' }"
          v-if="!sideMenuPermant"
        >
          <v-list-item-content>
            <v-btn
              class="btn_text  text-none shadow"
              rounded
              color="secondary"
              >Create Service</v-btn
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          color="secondary"
          :to="{ name: 'GenerateService' }"
          v-if="sideMenuPermant"
        >
          <v-list-item-avatar size="30" tile>
            <v-img src="../assets/app.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium btn_text  ">{{
              $t("add_service")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list>
        <v-list-item
          color="secondary"
          :to="{ name: 'Notifications' }"
          v-if="sideMenuPermant"
        >
          <v-list-item-avatar size="30" tile>
            <v-badge
              color="secondary"
              overlap
              dot
              :value="notifications.length > 0"
            >
              <v-avatar size="30">
                <v-img src="../assets/notification.svg"></v-img>
              </v-avatar>
            </v-badge>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium btn_text  ">{{
              $t("add_service")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="secondary" @click="$emit('show-support-dialog')">
          <v-list-item-avatar size="30" tile>
            <v-img src="../assets/faq.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class=" btn_text ">{{
              $t("support")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-avatar size="30" tile>
            <v-img src="../assets/logout.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class=" btn_text ">{{
              $t("log_out")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <!-- Footer Links -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class=" btn_text x_small_text pointer">
              <a href="https://dsi.informationssicherheit.fraunhofer.de/en/dsi/participants-portal.iais.fraunhofer.de/full" target="_blank">Data Protection</a>
            </v-list-item-title>
            <v-list-item-title>
              <a href="https://dsi.informationssicherheit.fraunhofer.de/en/impressum/atlas-participants-portal/full" target="_blank">Impressum</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import getEnv from "@/utils/env";

export default {
  name: "MainSidebar",
  components: {},

  data() {
    return {      
      mini: false,
      menu: false,
      user_id: null,
      participant_user_role_id: null,
      participant_organization: "",
      userTypes: {
        ADMINISTRATOR: 1,
        PARTICIPANT: 2
      },
      participantUserTypes: {
        ADMINISTRATOR: 1,
        ASSISTANT: 2        
      },
      participantStatuses: {
        PENDING: 1,
        APPROVED: 2,
        REJECTED: 3,
        SUSPENDED: 4
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },    

    notifications() {
      return this.$store.getters.getNotifications;
    },

    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    },
  },

  methods: {
    getImgUrl(icon) {
      let images = require.context("../assets/", false, /\.svg$/);
      return images("./" + icon + ".svg");
    },

    logout() {
      let kcOptions = {
        url: getEnv("VUE_APP_KEYCLOAK_URL"),
        realm: getEnv("VUE_APP_KEYCLOAK_REALM"),
        clientId: getEnv("VUE_APP_KEYCLOAK_CLIENT_ID"),
        onLoad: "login-required",
        encodedRedirectUri: window.location.origin
      };
      this.$store.dispatch("logout").then(() => {
        window.location.href = `${kcOptions.url}/realms/${kcOptions.realm}/protocol/openid-connect/logout?redirect_uri=${kcOptions.encodedRedirectUri}`;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.child-element {
  height: 32vh;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>

const getDefaultState = () => {
  return {
    has_error: true,
    service: {
      specification_id: undefined,
      description: "",
      capabilities: [],
      authorization_url: "",
      name: ""
    }
  };
};

export const service = {
  state: getDefaultState,
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    set_error(state, payload) {
      state.has_error = payload;
    },
    add_custom_value_capabilities(state) {
      return state.service.capabilities.forEach(c => {
        return Object.assign(c, { is_custom: false });
      });
    },
    add_custom_capabilities(state, payload) {
      state.service.capabilities.push(payload);
    },
    remove_capabilities(state, payload) {
      return state.service.capabilities.splice(
        state.service.capabilities.findIndex(i => {
          return i.label === payload.label;
        }),
        1
      );
    }
  },
  actions: {},
  getters: {
    getService: state => state.service,
    getHasError: state => state.has_error
  }
};

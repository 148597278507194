import * as Keycloak from "./plugins/keycloak";
import axios from "axios";
import Vue from "vue";
import Vuelidate from "vuelidate";
import getEnv from "@/utils/env";

import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Default from "./layouts/Default";
import NoSidebar from "./layouts/NoSidebar";
import i18n from "./i18n";
import NotifyPlugin from "vue-easy-notify";
import "vue-easy-notify/dist/vue-easy-notify.css"; // import style

Vue.use(NotifyPlugin);
Vue.use(Vuelidate);
Vue.component("default-layout", Default);
Vue.component("no-sidebar-layout", NoSidebar);

Vue.config.productionTip = false;

let kcOptions = {
  url: getEnv("VUE_APP_KEYCLOAK_URL"),
  realm: getEnv("VUE_APP_KEYCLOAK_REALM"),
  clientId: getEnv("VUE_APP_KEYCLOAK_CLIENT_ID"),
  onLoad: "login-required"
};
let keycloak = Keycloak(kcOptions);
keycloak.init({ onLoad: kcOptions.onLoad }).then(auth => {
  if (!auth) {
    window.location.reload();
  }


  // Make Keycloak instance available through Vue
  Vue.prototype.$keycloak = keycloak;

  localStorage.setItem("atlas_token", keycloak.token);

  const token = localStorage.getItem("atlas_token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount("#app");
  setInterval(() => {
    keycloak
      .updateToken(70)
      .then(refreshed => {
        if (refreshed) {
          localStorage.setItem("atlas_token", keycloak.token);
          const token = localStorage.getItem("atlas_token");
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
      })
      .catch(() => {
        console.log("Failed to refresh token");
      });
  }, 60000);
});

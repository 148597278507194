import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/dashboard/Home.vue";
import Settings from "../views/dashboard/Settings.vue";
import Users from "../views/dashboard/Users";
import Boarding from "../views/Boarding";
import Service from "../views/dashboard/Service";
import Vendors from "../views/admin/Vendors";
import Vendor from "../views/admin/Vendor";
import Notifications from "../views/dashboard/Notifications";
import Faq from "../views/dashboard/Faq";
import Tutorial from "@/views/dashboard/Tutorial";
import GenerateService from "@/views/dashboard/GenerateService";


Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem("vue-token")) {
    next("/");
    return;
  }
  next();
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/notifications",
    name: "Notifications",
    component: Notifications,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/participants",
    name: "Vendors",
    component: Vendors,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/faq",
    name: "FAQ",
    component: Faq,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/tutorial",
    name: "Tutorial",
    component: Tutorial,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/generate",
    name: "GenerateService",
    component: GenerateService,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/vendors/:id",
    name: "Vendor",
    component: Vendor,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/services/:id",
    name: "Service",
    component: Service,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/users",
    name: "Users",
    component: Users,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/app/settings",
    name: "Settings",
    component: Settings,
    meta: { layout: "default", requiresAuth: true }
  },
  {
    path: "/boarding",
    name: "Boarding",
    component: Boarding,
    meta: { layout: "no-sidebar" }
  },
  {
    path: "/lost",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
    meta: { layout: "no-sidebar" }
  },
  {
    path: "/success",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Success.vue"),
    meta: { layout: "no-sidebar" }
  },
  {
    path: "/verified",
    name: "Verified",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Verified.vue"),
    meta: { layout: "no-sidebar" }
  },
  {
    path: "/part-verified",
    name: "PartVerified",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PartVerified.vue"),
    meta: { layout: "no-sidebar" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next("/lost");
  } else {
    next();
  }
});



export default router;

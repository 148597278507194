const getDefaultState = () => {
  return {
    loading: false,
    snack: {},
    dialog: false,
    alert_state: false
  };
};

export const global = {
  state: getDefaultState,
  mutations: {
    SET_LOADER: (state, payload) => (state.loading = payload),
    SET_SNACKBAR: (state, payload) => (state.snack = payload),
    SET_DIALOG: (state, payload) => (state.dialog = payload),
    SET_ALERT_STATE: (state, payload) => (state.alert_state = payload),
  },
  getters: {
    getloader: state => state.loading,
    getsnackbar: state => state.snack,
    getDialog: state => state.dialog,
    getAlertState: state => state.alert_state
  }
};

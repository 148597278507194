<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="12">
        <one title="Create  profile"></one>
        <v-btn
          class="text-none  btn_text mt-3"
          color="link"
          exact
          text
          :to="{ name: 'Login' }"
        >
          {{ $t("are_member") }}
          <span class="btn_text ml-1"> {{ $t("login") }} </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import One from "../components/boarding/One";

export default {
  name: "Validation",
  components: { One }
};
</script>

<style scoped></style>

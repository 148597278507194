export class VersionManager {

    _versionTree = {}

    constructor() {

    }

    /**
     * Register an item version to this VersionManager.
     * @param {*} name The item name.
     * @param {*} versionText The version text.
     * @param {*} attachment Optional field to story any extra data you want along with this record.
     */
    add(name, versionText, attachment=null) {
        let namedVersion = new NamedVersion(name, versionText, attachment);

        if (!this._versionTree.hasOwnProperty(namedVersion.name)) {
            this._versionTree[namedVersion.name] = {};
        }
        if (!this._versionTree[namedVersion.name].hasOwnProperty(namedVersion.major)) {
            this._versionTree[namedVersion.name][namedVersion.major] = []
        }
        this._versionTree[namedVersion.name][namedVersion.major].push(namedVersion);
    }

    /**
     * Get a record from this VersionManager
     * @param {*} name 
     * @param {*} versionText 
     * @returns A NamedVersion object or null.
     */
    get(name, versionText) {
        let version = new Version(versionText);
        
        let item = null;

        if (
            this._versionTree.hasOwnProperty(name) &&
            this._versionTree[name].hasOwnProperty(version.major) &&
            this._versionTree[name][version.major].length > 0
        ) {
            // We found multiple minor flavors for the same major version           
            let flavors = this._versionTree[name][version.major];
            flavors.forEach(flavor => {
                if (version.isSameTo(flavor)) {
                    item = flavor;
                }
            });
        }

        return item;
    }

    /**
     * Get the latest patch version for the given MAJOR.MINOR
     */
    getLatestPatch(name, versionText) {
        let namedVersion = new NamedVersion(name, versionText);

        let latest = namedVersion;

        if (
            this._versionTree.hasOwnProperty(namedVersion.name) &&
            this._versionTree[namedVersion.name].hasOwnProperty(namedVersion.major) &&
            this._versionTree[namedVersion.name][namedVersion.major].length > 1
        ) {
            let flavors = this._versionTree[namedVersion.name][namedVersion.major];            
            flavors.forEach(flavor => {
                if (flavor.isNewerThan(latest)) {
                    latest = flavor;
                }
            });
        }

        return latest;
    }

    isDeprecated(name, versionText) {
        let namedVersion = new NamedVersion(name, versionText);
        let deprecated = false;

        if (
            this._versionTree.hasOwnProperty(namedVersion.name) && 
            this._versionTree[namedVersion.name].hasOwnProperty(namedVersion.major) &&
            this._versionTree[namedVersion.name][namedVersion.major].length > 1
        ) {
            // We found multiple minor flavors for the same major version           
            let flavors = this._versionTree[namedVersion.name][namedVersion.major];            

            // Check if the provided item is deprecated
            flavors.forEach(flavor => {
                if (namedVersion.isOlderThan(flavor)) {
                    deprecated = true;
                }
            });
        }

        return deprecated;
    }

    isMissing(name, versionText) {
        let namedVersion = new NamedVersion(name, versionText);
        let missing = true;

        if (
            this._versionTree.hasOwnProperty(namedVersion.name) &&
            this._versionTree[namedVersion.name].hasOwnProperty(namedVersion.major) &&
            this._versionTree[namedVersion.name][namedVersion.major].length > 0
        ) {
            // We found multiple minor flavors for the same major version           
            let flavors = this._versionTree[namedVersion.name][namedVersion.major];            
            flavors.forEach(flavor => {
                if (namedVersion.isSameTo(flavor)) {
                    missing = false;
                }
            });
        }
        
        return missing;
    }

}

export class Version {

    major = null;
    minor = null;
    patch = null;

    constructor(versionText) {
        if (typeof versionText === 'string') {
            let parts = versionText.split(".")
            if (parts.length > 0) {
                this.major = parts[0]
            }
            if (parts.length > 1) {
                this.minor = parts[1]
            }
            if (parts.length > 2) {
                this.patch = parts[2]
            }
        }
    }

    isNewerThan(other) {        
        if (this.major < other.major) {
            return false;
        } else if (this.major == other.major && this.minor < other.minor) {
            return false;
        } else if (this.major == other.major && this.minor == other.minor && this.patch < other.patch) {
            return false;
        } else if (this.major == other.major && this.minor == other.minor && this.patch == other.patch) {
            return false;
        } 

        return true;
    }

    isOlderThan(other) {
        if (this.major > other.major) {
            return false;
        } else if (this.major == other.major && this.minor > other.minor) {
            return false;
        } else if (this.major == other.major && this.minor == other.minor && this.patch > other.patch) {
            return false;
        } else if (this.major == other.major && this.minor == other.minor && this.patch == other.patch) {
            return false;
        }

        return true;
    }

    isSameTo(other) {
        if (this.major == other.major && this.minor == other.minor && this.patch == other.patch) {
            return true;
        }

        return false;
    }

    hasSameMajorTo(other) {
        if (this.major == other.major) {
            return true;
        }

        return false;
    }

    getVersionText(showParts=3) {
        let parts = [];
        if (this.major !== null && showParts > 0) {
            parts.push(this.major);

            if (this.minor !== null && showParts > 1) {
                parts.push(this.minor);

                if (this.patch !== null && showParts > 2) {
                    parts.push(this.patch);
                }
            }
        }
        return parts.join(".");
    }

}

export class NamedVersion extends Version {

    name = null;
    attachment = null;

    constructor(name, versionText, attachment=null) {
        super(versionText);
        this.name = name;
        this.attachment = attachment;
    }

}
<template>
  <v-container class="mt-12 px-lg-12">
    <v-row>
      <v-col cols="12" lg="10" md="10" class=" mt-n10">
        <p class="background_text pt-0 mt-0 " v-if="!sideMenuPermant">
          {{ $t("notifications") }}
        </p>
        <v-container :class="{ services_grid: !sideMenuPermant }">
          <v-row>
            <v-col cols="12" sm="12" xs="12" lg="12" xl="12" md="12">
              <vuescroll :ops="ops">
                <div class="child-element">
                  <transition-group name="list" tag="div">
                    <v-card
                      v-for="(item, index) in notifications"
                      :key="item.id"
                      flat
                      class="mt-2 "
                      style="border-radius: 5px"
                    >
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            {{ item.message }}
                          </v-list-item-content>

                          <v-list-item-avatar tile size="20">
                            <v-btn @click="remove(index, item.id)" icon x-small>
                              <v-avatar tile size="20">
                                <v-img
                                    src="../../assets/close.svg"
                                    contain
                                    aspect-ratio="2"
                                ></v-img>
                              </v-avatar>

                            </v-btn>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </transition-group>
                </div>
              </vuescroll>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vuescroll from "vuescroll";

export default {
  name: "Notifications",
  components: {
    vuescroll
  },
  data() {
    return {
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      ops: {
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: false,
          keepShow: false,
          background: "#44636A",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          size: "6px",
          disable: false
        }
      }
    };
  },

  created() {
    this.$store.dispatch("get_notifications");
    this.$store.commit("SET_LOADER", false);
  },
  computed: {
    notifications() {
      return this.$store.getters.getNotifications;
    },
    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    }
  },
  methods: {
    remove(index, id) {
      this.$store.dispatch("remove_notifications", id).then(() => {
        return this.notifications.splice(index, 1);
      });
    },
  }
};
</script>

<style scoped lang="scss">
.child-element {
  height: 75vh;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>

<template>
  <v-container class="mt-15 px-lg-12 ">
    <v-row>
      <v-col cols="12" lg="8" xl="7" md="12">
        <transition name="slide-fade" mode="out-in">
          <v-card
            flat
            class="pa-2 pa-lg-6 shadow-box "
            color="white"
            v-if="has_data"
          >
            <v-toolbar dense flat color="transparent">
              <v-btn icon :to="{ name: 'Home' }" text>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>

              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn light icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item>
                    <v-btn
                      text
                      block
                      color="red"
                      class=" btn_text text-none"
                      @click="service_delete = true"
                    >
                      {{ $t("delete") }}</v-btn
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-card-title> </v-card-title>

            <v-card-text class="mt-2">
              <p
                class="pb-0 mb-0 text-right"
                style="font-size: 16px"
                :style="{
                  color: statusColor.color
                }"
              >
                {{ statusColor.title }}
              </p>
              <p
                class="pl-0 mt-0 mb-4 text-right"
                style="font-size: 16px"
                :style="{
                  color: statusServiceColor(service.validation_status).color
                }"
              >
                {{ serviceStatus.title }}
              </p>

              <!-- Service Name -->
              <p
                class="primary--text text-h4  mb-2"
                style="font-size: 18px"
              >
                {{ service.name }}
              </p>
              <p class="black--text " style="font-size: 15px">
                {{ service.description }}
              </p>

              <!-- Website URL -->
              <p class=" mb-0 font-weight-bold" style="font-size: 12px">
                Website
              </p>
              <p class="mt-0 black--text" style="font-size: 14px">
                <a 
                  v-if="service.website_url != null"
                  :href="service.website_url"
                >
                  {{ service.website_url }}
                </a>
                <span v-else>-</span>
              </p>

              <!-- Organization -->
              <div v-if="user.user_type_id < 2">
                <p class=" mb-0 font-weight-bold" style="font-size: 12px">
                  Organization
                </p>
                <p class=" mt-0 black--text" style="font-size: 14px">
                  {{ service.participant.organization }}
                </p>
              </div>

              <v-divider class="mb-3" />

              <!-- Service URL -->
              <p class=" mb-0 font-weight-bold" style="font-size: 12px">
                Service URL
              </p>
              <p class="mt-0 black--text" style="font-size: 14px">
                {{ service.service_base_url }}
              </p>

              <!-- Specification -->
              <p class=" mb-0 font-weight-bold" style="font-size: 12px">
                Service Specification
              </p>
              <div v-if="service.specification !== null">
                <p class=" mb-0 black--text" style="font-size: 14px">
                  {{ service.specification.name }} v{{service.specification.majorMinorVersion}}
                  <span class="template-notice template-archived" v-if="service_specification_archived">
                    ARCHIVED
                  </span>
                  <span class="template-notice template-deprecated" v-if="service_specification_deprecated">
                    DEPRECATED
                  </span>
                </p>
                <p class=" black--text font-italic" style="font-size: 14px">
                  {{ service.specification.description }}
                </p>
              </div>
              <div v-else>
                <p class=" mb-0 black--text" style="font-size: 14px">
                  Missing!
                </p>
                <p class=" black--text font-italic" style="font-size: 14px">                  
                </p>
              </div>

              <v-divider class="mb-3" />

              <!-- Authorization -->
              <p class=" mb-0 font-weight-bold " style="font-size: 12px">
                Authorization
              </p>

              <p
                class=" mb-0 black--text"
                style="font-size: 14px"
                v-if="service.oauth2_registration_url"
              >
                <span class="secondary--text">Client Registration URL:</span>
                {{ service.oauth2_registration_url }}
              </p>
              <p class=" mb-0 black--text" style="font-size: 14px" v-else>
                <span class="secondary--text">Validator client id:</span>
                {{ service.validator_client_id }}
              </p>

              <p class=" black--text mb-0" style="font-size: 14px">
                <span class="secondary--text">Auth URL:</span>
                {{ service.oauth2_auth_url }}
              </p>
              <p class=" black--text mb-0" style="font-size: 14px">
                <span class="secondary--text">Token URL:</span>
                {{ service.oauth2_token_url }}
              </p>
              <p 
                class=" black--text" 
                style="font-size: 14px"
                v-if="service.oauth2_scopes"
              >
                <span class="secondary--text">Scopes:</span>
                {{ service.oauth2_scopes }}
              </p>
            </v-card-text>

            <v-card-actions v-if="user.user_type_id == 1">
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  service.service_status_id > 0 &&
                    service.service_status_id !== 2
                "
                large
                color="red"
                dark
                class=" btn_text text-none"
                @click="rejectService()"
              >
                {{ $t("reject") }}</v-btn
              >
              <v-btn
                large
                v-if="
                  service.service_status_id > 0 &&
                    service.service_status_id !== 2
                "
                color="success"
                class=" btn_text text-none"
                @click="approveService()"
              >
                {{ $t("approve") }}</v-btn
              >
            </v-card-actions>

            <v-card-actions v-else>
              <v-spacer></v-spacer>
              <v-btn
                v-if="service.service_status_id === 0"
                color="secondary"
                class="btn_text text-none elevation-0"
                large
                :disabled="!allow_publishing"
                @click="publishService()"
              >
                {{ $t("publish") }}</v-btn
              >
            </v-card-actions>
          </v-card>
          <v-skeleton-loader
            class="shadow-box"
            v-else
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </transition>
      </v-col>
    </v-row>

    <v-dialog v-model="more_info" max-width="500" persistent>
      <v-card class="pa-6 shadow-box">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" primary--text text-wrap page_sub_heading "
              >
                Service endpoint info</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-avatar size="40">
              <v-btn icon @click="more_info = false">
                <v-img
                  src="../../assets/close.svg"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
        <v-card-text>
          <p
            class="ml-2 primary--text mt-0 font-weight-bold"
            style="font-size: 16px"
          >
            Endpoint comments
          </p>
          <p class="black--text ml-2" style="font-size: 16px">
            {{ endpoint.comments }}
          </p>
          <p
            class="ml-2 primary--text mt-0 font-weight-bold"
            style="font-size: 16px"
          >
            Endpoint custom id
          </p>
          <p class="black--text ml-2" style="font-size: 16px">
            {{ endpoint.custom_id }}
          </p>
          <p
            class="ml-2 primary--text mt-0 font-weight-bold"
            style="font-size: 16px"
          >
            Endpoint specification description
          </p>
          <p class="black--text ml-2" style="font-size: 16px">
            {{ endpoint.specification.description }}
          </p>
          <p
            class="ml-2 primary--text mt-0 font-weight-bold"
            style="font-size: 16px"
          >
            Endpoint specification core api id
          </p>
          <p class="black--text ml-2" style="font-size: 16px">
            {{ endpoint.specification.core_api_id }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="service_delete" max-width="500" persistent>
      <v-card class="pa-2">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" primary--text text-wrap page_sub_heading "
              >
                {{ $t("delete") }} service</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-avatar tile size="20">
              <v-img
                @click="service_delete = false"
                src="../../assets/close.svg"
                aspect-ratio="1"
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-card-text class="black--text">
          {{ $t("delete_text") }}
        </v-card-text>

        <v-card-actions class="card_action_bg">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            class="text-none btn_text black--text mr-4"
            text
            @click="service_delete = false"
          >
            {{ $t("close") }}
          </v-btn>

          <v-btn
            class="text-none btn_text elevation-0"
            dark
            large
            color="error"
            @click="deleteService"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" max-width="500" persistent>
      <v-card class="pa-2">
        <v-list >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" red--text   ml-2"
              >
                Publication Failed 
            </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar tile size="20">
              <v-img
                @click="errorDialog = false"
                src="../../assets/close.svg"
                aspect-ratio="1.5"
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-card-text>
          <p class="mb-6 ">
            {{ errorTitle }}
          </p>
          <p v-for="(error, index) in publishErrors" :key="index" class="mb-1 black--text">
            {{ error }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { VersionManager, Version } from "../../utils/VersionManager"

export default {
  name: "Service",
  data() {
    return {
      dialog: false,
      service_delete: false,
      errorDialog: false,
      errorTitle: "",
      publishErrors: [],
      select: [],
      has_data: false,
      service: {},
      service_specification_archived: false,
      service_specification_deprecated: false,      
      allow_publishing: false,
      more_info: false,
      tag: {},
      endpoint: {
        comments: "",
        custom_id: "",
        specification: {
          description: "",
          core_api_id: ""
        }
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    statusColor() {
      if (this.service.service_status_id === 0)
        return { id: 0, title: "Draft", color: "#C0C0C0" };
      if (this.service.service_status_id === 1)
        return {
          id: 1,
          title: "Pending to be approved",
          color: "#ff9922"
        };
      if (this.service.service_status_id === 2)
        return { id: 2, title: "Approved by admin", color: "#008844" };
      if (this.service.service_status_id === 3)
        return { id: 3, title: "Rejected by admin", color: "#cc0000" };
      return "";
    }
  },
  created() {
    this.getService();
  },
  methods: {
    getService() {
      this.$axios
        .get(`/services/${this.$route.params.id}`)
        .then(res => {
          this.service = res.data.data;

          if (this.service.specification !== null) {
            this.service.specification.majorMinorVersion = new Version(this.service.specification.version).getVersionText(2);  
            this.getSpecifications();
          }
        })
        .catch(() => {})
        .finally(() => {
          this.has_data = true;
        });
    },

    getSpecifications() {
      this.$axios({
        method: "GET",
        url: "/specifications"
      })
        .then(res => {
          let specifications = [];
          let versionManager = new VersionManager();
          
          // Feed the version manager
          res.data.data.forEach(specification => {
            versionManager.add(specification.name, specification.version, specification.core_api_id)
          })
          window.versionManager = versionManager;

          // Check if the selected template version is archived
          if (versionManager.isMissing(this.service.specification.name, this.service.specification.version)) {
            let latestPatch = versionManager.getLatestPatch(this.service.specification.name, this.service.specification.version);
            if (latestPatch.attachment == this.service.specification.core_api_id) {
              this.allow_publishing = true;
            } else {
              this.service_specification_archived = true;
            }
          }
          // Check if the selected template version is deprecated
          else if (versionManager.isDeprecated(this.service.specification.name, this.service.specification.version)) {
            this.service_specification_deprecated = true;
            this.allow_publishing = true;
          } else {
            this.allow_publishing = true;
          }
        })
    },

    publishService() {
      this.errorTitle = "";
      this.publishErrors = [];
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$store.commit("SET_LOADER", true);
      this.$axios
        .patch(`/services/${this.$route.params.id}/publish`)
        .then(res => {
          this.service.service_status_id = 1;
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message
          });
        })
        .catch(err => {
          console.log(err.response.data);
          this.errorTitle = err.response.data.message;
          this.publishErrors = err.response.data.data;
          this.errorDialog = true;
        })
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    },

    rejectService() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$store.commit("SET_LOADER", true);
      this.$axios
        .patch(`/admin/service/${this.$route.params.id}/reject`)
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        })
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    },
    openInfo(endpoint) {
      this.endpoint = endpoint;
      this.more_info = true;
    },
    approveService() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$store.commit("SET_LOADER", true);
      this.$axios
        .patch(`/admin/service/${this.$route.params.id}/approve`)
        .then(res => {
          this.service.service_status_id = 2;
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message
          });
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        })
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    },
    statusServiceColor(status) {
      if (status === null)
        return (this.serviceStatus = {
          title: "Service is not validated yet.",
          color: "#C0C0C0"
        });
      if (status === false)
        return (this.serviceStatus = {
          title: "Service validation failed",
          color: "#cc0000"
        });
      if (status === true)
        return (this.serviceStatus = {
          title: "Service validation success",
          color: "#008844"
        });
    },
    deleteService() {
      this.$store.commit("SET_LOADER", true);
      this.$axios
        .delete(`/services/${this.$route.params.id}`)
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    }
  }
};
</script>

<style scoped>
.template-notice {
  background-color: #C0C0C0; 
  color: white;
  border-radius: 3px;
  padding: 0 5px;
}

.template-archived {
  background-color: #cc0000; 
}

.template-deprecated {
  background-color: #ff9922; 
}
</style>

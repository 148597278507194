<template>
  <v-container class=" px-lg-12">
    <v-row>
      <v-col cols="12" lg="10" md="10">
        <p class="background_text pt-0 mt-0 " v-if="!sideMenuPermant">
          {{ $t("help") }}
        </p>
        <v-container :class="{ services_grid: !sideMenuPermant }">
          <v-row>
            <v-col cols="12" sm="12" xs="12" lg="10" xl="10" md="12">
              <v-card class="pa-4 shadow-box">
                <v-card-text>
                  <p class="page_sub_heading_small">{{ $t("title") }}</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce ipsum metus, gravida volutpat eros vel, semper viverra
                    ligula. Etiam vestibulum non lorem eu dignissim.
                    Pellentesque nec tincidunt magna. Suspendisse interdum
                    turpis eu magna sagittis bibendum. Aenean vitae tincidunt
                    lectus. Ut imperdiet dolor et orci facilisis, at placerat
                    arcu sagittis. Sed a neque magna. Morbi ac lectus accumsan,
                    vestibulum odio quis, ullamcorper tellus. Mauris consequat
                    quis massa tempor aliquam.
                  </p>
                  <p class="page_sub_heading_small">{{ $t("title") }}</p>
                  <p>
                    Aenean fermentum sapien nec congue fermentum. Proin lobortis
                    pulvinar nunc tristique pretium. Integer tincidunt magna
                    dolor, nec faucibus est consectetur quis. Nulla nec tortor
                    ut nisi viverra gravida blandit sit amet tellus. Proin ut
                    orci erat. Pellentesque imperdiet a ex non maximus. In hac
                    habitasse platea dictumst. Duis suscipit nisi lacus, sit
                    amet vulputate turpis bibendum et. Mauris ac purus
                    condimentum, tempus ipsum ac, convallis lorem. Cras eu
                    scelerisque massa, vel auctor ipsum. Mauris egestas at est a
                    sodales. Donec sed sem porttitor, congue urna ut, convallis
                    nunc. Suspendisse sagittis tempor metus, vitae elementum
                    magna mollis vel. Morbi purus mi, mattis vel est in, tempor
                    eleifend risus. Donec ultrices consequat nulla at volutpat.
                  </p>
                  <p class="page_sub_heading_small">{{ $t("title") }}</p>
                  <p>
                    Sed nulla libero, euismod vel molestie ornare, pharetra id
                    tortor. Fusce auctor mattis nibh placerat dignissim. Morbi
                    porta pulvinar mi. Donec felis dolor, porttitor sed augue
                    sit amet, tincidunt finibus velit. Praesent nibh tortor,
                    vehicula et finibus ut, gravida ut felis. Donec maximus
                    metus magna, sagittis porttitor risus volutpat eget. Nulla
                    sed enim vulputate, sodales purus eu, dapibus mi. Phasellus
                    ultrices molestie ex nec commodo.
                  </p>
                  <p class="page_sub_heading_small">{{ $t("title") }}</p>
                  <p>
                    In tempus aliquet ex, eu rutrum arcu ultrices et. Vestibulum
                    ac nunc efficitur, tempus diam nec, vehicula sem. Morbi
                    dolor elit, pellentesque eu nunc ut, fringilla dictum erat.
                    In hac habitasse platea dictumst. Sed finibus, est id
                    aliquam interdum, dolor nisl bibendum ex, ut facilisis
                    turpis odio a erat. Suspendisse ornare libero porta turpis
                    scelerisque, id sodales elit fermentum. Duis interdum orci
                    at magna sollicitudin, id auctor justo porttitor. Fusce sed
                    placerat quam.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="help_dialog" max-width="500" persistent>
      <v-card class="pa-4 ">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" secondary--text text-wrap page_heading"
                >{{ $t("support") }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-avatar size="40">
              <v-btn icon @click="help_dialog = false">
                <v-img
                  src="../../assets/close.svg"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-card-text>
          <v-text-field
            :label="$t('title_subject')"
            v-model="title_subject"
            color="secondary"
          ></v-text-field>
          <v-textarea
            :label="$t('message_subject')"
            v-model="message_subject"
            color="secondary"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            class="text-none btn_text mr-4 black--text"
            text
            @click="help_dialog = false"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            class="text-none btn_text elevation-0"
            dark
            large
            color="secondary"
            @click="support"
          >
            {{ $t("sent") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      @click="help_dialog = true"
      fixed
      dark
      fab
      bottom
      right
      color="secondary"
    >
      <v-icon>mdi-mail</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      help_dialog: false,
      title_subject: "",
      message_subject: ""
    };
  },
  created() {
    this.$store.commit("SET_LOADER", false);
  },
  computed: {
    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    }
  },
  methods: {
    support() {
      this.$store.commit("SET_SNACKBAR", { state: false });

      this.$axios({
        method: "post",
        url: "/support",
        data: {
          subject: this.title_subject,
          message: this.message_subject
        }
      })
        .then(res => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message,
            color: "secondary"
          });
          this.help_dialog = false;
          this.title_subject = "";
          this.message_subject = "";
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message,
            color: "primary"
          });
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>

<template>
  <v-container class="mt-15 px-lg-12">
    <v-row>
      <v-col cols="12" lg="12" md="12">
        <div class="d-lg-flex ">
          <div class=" mr-auto">
            <v-text-field
              flat
              rounded
              hide-details
              solo
              clearable
              @click:clear="clearParticipants"
              :label="$t('search_vendors')"
              v-model="searchUser"
              style="width: 500px"
            >
              <template slot="append">
                <v-btn
                  fab
                  dark
                  small
                  color="secondary"
                  class="mr-n4"
                  @click="getParticipants"
                >
                  <v-icon dark>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
          <div class="mt-5 mt-lg-0 mb-4">
            <v-btn
              class="mr-2 shadow-box text-none btn_text"
              dark
              :block="sideMenuPermant"
              large
              color="secondary"
              @click="user_dialog = true"
            >
              Add User
            </v-btn>
          </div>
        </div>
      </v-col>

      <!-- Users Table -->
      <v-col cols="12" lg="10" md="10" class=" mt-n10">
        <p class="background_text pt-0 mt-0 " v-if="!sideMenuPermant">
          {{ $t("users") }}
        </p>
        <v-container :class="{ services_grid: !sideMenuPermant }">
          <v-row>
            <v-col cols="12" sm="12" xs="12" lg="10" xl="10" md="12">
              <transition name="slide-fade" mode="out-in">
                <v-data-table
                  key="tablet"
                  v-if="!loadUsers"
                  :headers="headers"
                  :items="participants"
                  :items-per-page="10"
                  class="shadow-box"
                >

                  <!-- Name -->
                  <template v-slot:item.email="{ item }">
                    <span v-if="isUserRegistered(item)">
                      {{ item.email }}
                    </span>
                    <span v-else class="grey--text">
                      {{ item.email }}
                    </span>
                  </template>

                  <!-- Name -->
                  <template v-slot:item.name="{ item }">
                    <span v-if="isUserRegistered(item)">
                      {{ item.name || item.username }}
                    </span>
                    <span v-else class="grey--text">
                      Pending Registration&hellip;
                    </span>
                  </template>

                  <!-- Action -->
                  <template v-slot:item.remove="{ item }">
                    <v-btn
                      v-if="user.id!=item.id"
                      color="error"
                      text
                      class="btn_text text-none "
                      @click="(user_delete = true), (user_id = item.id)"
                    >
                      Remove
                    </v-btn>
                    <span 
                      v-else 
                      class="grey--text"
                      title="This is you!"
                    >
                      ---
                    </span>
                  </template>
                </v-data-table>

                <v-skeleton-loader
                  v-else
                  key="skeleton"
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto shadow-box"
                ></v-skeleton-loader>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog v-model="user_dialog" max-width="500" persistent>
      <v-card class="pa-6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" secondary--text text-wrap page_heading"
                >{{ $t("invite_user") }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-avatar tile size="20">
              <v-img
                @click="user_dialog = false"
                src="../../assets/close.svg"
                aspect-ratio="1"
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-card-text>
          <v-text-field
            :label="$t('email')"
            color="secondary"
            type="email"
            v-model="email"
          >
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none btn_text black--text"
            text
            @click="user_dialog = false"
          >
            {{ $t("close") }}
          </v-btn>

          <v-btn
            class="text-none btn_text "
            color="secondary"
            depressed
            large
            @click="inviteUsers"
          >
            {{ $t("invite_user") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="user_delete" max-width="500" persistent>
      <v-card class="pa-2">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class=" error--text text-wrap page_heading">
                {{ $t("remove_warning") }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-avatar tile size="20">
              <v-img
                @click="user_delete = false"
                src="../../assets/close.svg"
                aspect-ratio="1"
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-card-text>
          {{ $t("remove_assistant_text") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none btn_text black--text"
            text
            @click="user_delete = false"
          >
            {{ $t("close") }}
          </v-btn>

          <v-btn
            class="text-none btn_text "
            depressed
            dark
            color="error"
            @click="deleteUser"
          >
            {{ $t("remove") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Users",

  data() {
    return {
      user_dialog: false,
      user_delete: false,
      loadUsers: true,
      user_id: "",
      username: "",
      email: "",
      name: "",
      searchUser: "",
      headers: [
        {
          text: this.$t("full_name"),
          align: "center",
          // sortable: false,
          value: "name"
        },
        { 
          text: this.$t("email"), 
          value: "email", 
          align: "center" 
        },
        { 
          text: "", 
          value: "remove", 
          align: "center" 
        }
      ],
      participants: [],
      select: ["Vuetify", "Programming"],
      items: ["Programming", "Design", "Vue", "Vuetify"],
      tags: [
        { title: "approved", color: "#63CA13" },
        { title: "pending", color: "#939592" }
      ]
    };
  },

  created() {
    this.$store.dispatch("get_notifications");
    this.getParticipants();
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },

    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    },

    overlay() {
      return this.$store.getters.getloader;
    }
  },

  methods: {
    isUserRegistered(user) {
      if (user.name == null && user.username == null) {
        return false;
      }
      return true;
    },

    deleteUser() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .delete(`/participants/my-profile/users/${this.user_id}`)
        .then(() => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: this.$t("remove_assistant_success"),
            color: "primary"
          });

          this.participants = this.participants.filter(
            e => e.id !== this.user_id
          );
          this.user_delete = false;
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message,
            color: "primary"
          });
        });
    },

    inviteUsers() {
      this.$store.commit("SET_SNACKBAR", { state: false });

      this.$axios
        .post("/participants/my-profile/users/invite", { email: this.email })
        .then(() => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: "Invitation sent!",
            color: "primary"
          });
          const USER = {
            email: this.email,
            name: "Pending",
            username: "Pending"
          };
          this.participants.push(USER);
          this.getParticipants();
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message,
            color: "primary"
          });
        })
        .finally(() => {
          this.user_dialog = false;
          this.email = "";
        });
    },

    getParticipants() {
      this.loadUsers = true;
      console.log(this.searchUser);
      this.$axios
        .get(`/participants/my-profile/users`, {
          params: { filter: this.searchUser }
        })
        .then(res => {
          this.participants = res.data.data;
        })
        .finally(() => {
          this.loadUsers = false;
        });
    },

    clearParticipants() {
      this.loadUsers = true;
      this.$axios
        .get(`/participants/my-profile/users`)
        .then(res => {
          this.participants = res.data.data;
        })
        .finally(() => {
          this.loadUsers = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

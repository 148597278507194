<template>
  <v-container class=" px-lg-12">
    <v-row>
      <v-col cols="12" lg="10" md="10">
        <p class="background_text pt-0 mt-0 " v-if="!sideMenuPermant">
          Service guide
        </p>
        <v-container :class="{ services_grid: !sideMenuPermant }">
          <v-row>
            <v-col cols="12" sm="12" xs="12" lg="10" xl="10" md="12">
              <v-card class="pa-4 shadow-box ">
                <v-card-text class="pb-0">
                  <v-select
                    v-model="select"
                    :items="specifications"
                    item-text="name"
                    item-value="id"
                    label="Service specification"
                    flat
                    outlined
                    return-object
                  ></v-select>
                </v-card-text>

                <v-card-text v-if="select.hasOwnProperty('id')" class="pt-0 ml-2">

                  <p
                      class="ml-2 primary--text  font-weight-bold"
                      style="font-size: 16px"
                  >
                    Service specification fields
                  </p>
                  <p class="black--text ml-2  " style="font-size: 16px" v-if="select.name">
                    <span class="font-weight-bold">Name:</span>
                    {{ select.name }}
                  </p>
                  <p class="black--text ml-2" style="font-size: 16px" v-if="select.description">
                    <span class="font-weight-bold">Description:</span>
                    {{ select.description }}
                  </p>
                  <p class="black--text ml-2" style="font-size: 16px" v-if="select.version">
                    <span class="font-weight-bold">Version:</span>
                    {{ select.version }}
                  </p>
                  <p class="black--text ml-2" style="font-size: 16px" v-if="select.specification_document_id">
                    <span class="font-weight-bold">Id:</span>
                    {{ select.core_api_id }}
                  </p>
                  <p class="black--text ml-2" style="font-size: 16px" v-if="select.specification_document_url">
                    <span class="font-weight-bold">Document url:</span>
                    {{ select.specification_document_url }}
                  </p>
                  <p
                    class="ml-2 primary--text mt-0 font-weight-bold mb-0"
                    v-if="select.endpoints.length > 0"
                    style="font-size: 16px"
                  >
                    Specification endpoints
                  </p>
                  <v-list>
                    <v-list-item
                      two-line
                      v-for="endpoint in select.endpoints"
                      :key="endpoint.id"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle class="font-weight-bold black--text">
                          <span class="font-weight-medium">Name:</span>
                          {{ endpoint.name }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle class="font-weight-bold black--text">
                          <span class="font-weight-medium">Description:</span>
                          {{ endpoint.description }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle class="font-weight-bold black--text">
                          <span class="font-weight-medium">Id:</span>
                          {{ endpoint.core_api_id }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle class="font-weight-bold black--text">
                          <span class="font-weight-medium">Mandatory:</span>
                          {{ endpoint.mandatory }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Tutorial",
  data() {
    return {
      select: {},
      specifications: []
    };
  },
  created() {
    this.getSpecifications();
  },
  computed: {
    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    }
  },
  methods: {
    getSpecifications() {
      this.$store.commit("SET_LOADER", true);
      this.$axios({
        method: "GET",
        url: "/specifications"
      })
        .then(res => {
          this.specifications = res.data.data;
        })
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>

<template>
    <v-dialog v-model="visible" width="500" persistent>
      <v-card class="pa-6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" secondary--text text-wrap page_heading"
              >
                <slot name="title"></slot>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text>
          <slot name="body"></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none   elevation-0 btn_text "
            color="secondary "
            large
            @click="onOk()"
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "OkDialog",
  props: ['visible'],

  data() {
    return {             
    };
  },

  methods: {
    onOk() {
      console.log("OK pressed");
      this.$emit('ok');
    } 
  }
}
</script>
<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="11" lg="4" md="5" class="text-center">
        <v-img
          src="../assets/404.svg"
          v-if="currentRouteName === 'NotFound'"
        ></v-img>

        <v-avatar
          tile
          class="mb-8"
          size="50%"
          v-if="currentRouteName !== 'NotFound'"
        >
          <v-img src="../assets/atlas.png" aspect-ratio="2" contain></v-img>
        </v-avatar>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </v-col>
    </v-row>
    <v-snackbar
      :value="snackbar.state"
      :timeout="timeout"
      :color="snackbar.color"
      absolute
      right
      rounded="pill"
      top
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "NoSidebar",
  data() {
    return {
      align: ["stretch"],
      timeout: 3000
    };
  },
  computed: {
    snackbar() {
      return this.$store.getters.getsnackbar;
    },
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

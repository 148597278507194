import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#44636A",
        secondary: "#EB7E61",
        background: "#f3f5f5",
        link: "#44636A",
        accent: "#8c9eff",
        error: "#b71c1c",
        evergreen: "#05392a",
        brownishgrey: "#747474",
        browngrey: "#9f9f9f",
        lightgrey: "#F3F3F4",
        black: "#000000",
        palesalmon: "#FFE9E7",
        coralpink: "#ff0000"
      }
    }
  }
});

<template>
  <div>
    <v-app-bar flat fixed color="background" v-if="!sideMenuPermant">
      <v-spacer></v-spacer>

      <v-btn icon class="mr-lg-4" :to="{ name: 'Notifications' }">
        <v-badge
          color="secondary"
          overlap
          dot
          :value="notifications.length > 0"
        >
          <v-avatar size="30">
            <v-img src="../assets/notification.svg"></v-img>
          </v-avatar>
        </v-badge>
      </v-btn>
    </v-app-bar>

    <MainSidebar
      v-on:show-support-dialog="showSupportDialog"
    />

    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :value="snackbar.state"
      :timeout="timeout"
      :color="snackbar.color"      
      rounded="pill"
      top
      right
    >
      {{ snackbar.text }}
    </v-snackbar>

    <!-- Support Dialog -->
    <v-dialog v-model="help_dialog" max-width="500" persistent>
      <v-card class="pa-4">
        <alert-bar :type="alert.type" :message="alert.message"></alert-bar>

        <v-list three-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" secondary--text text-wrap page_heading"
                >{{ $t("support") }}</v-list-item-title
              >
              <p class="medium_text mt-2">
                Do you experience any difficulties with the platform?
              </p>
              <p class="medium_text mt-1">
                Do you have suggestions or just want to reach out?
              </p>
              <p class="medium_text mt-1">Send us message!</p>
            </v-list-item-content>

            <v-list-item-avatar tile size="20">
              <v-img
                @click="help_dialog = false"
                src="../assets/close.svg"
                aspect-ratio="1"
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
        <v-card-text>
          <v-text-field
            :label="$t('title_subject')"
            v-model="title_subject"
            color="secondary"
          ></v-text-field>
          <v-textarea
            :label="$t('message_subject')"
            v-model="message_subject"
            color="secondary"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none btn_text elevation-0"
            dark
            block
            large
            color="secondary"
            @click="support"
          >
            {{ $t("sent") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AlertBar from "@/components/AlertBar";
import MainSidebar from "@/components/MainSidebar";

export default {
  name: "Default",
  components: { AlertBar, MainSidebar },
  data() {
    return {
      mini: false,
      menu: false,
      participant_organization: "",
      help_dialog: false,
      title_subject: "",
      message_subject: "",

      help_text: "",
      alert: {
        state: false,
        message: "",
        type: "success", // , warning, error
        timeout: 5000
      },
      timeout: 3000
    };
  },
  computed: {
    overlay() {
      return this.$store.getters.getloader;
    },
    snackbar() {
      return this.$store.getters.getsnackbar;
    },
    notifications() {
      return this.$store.getters.getNotifications;
    },
    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    },
  },

  methods: {
    remove(index, id) {
      this.$store.dispatch("remove_notifications", id).then(() => {
        return this.notifications.splice(index, 1);
      });
    },

    showSupportDialog() {
      this.help_dialog = true;
    },

    support() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios({
        method: "post",
        url: "/support",
        data: {
          subject: this.title_subject,
          message: this.message_subject
        }
      })
        .then(res => {
          this.title_subject = "";
          this.message_subject = "";
          this.$store.commit("SET_ALERT_STATE", true);
          this.alert = {
            message: res.data.message,
            type: "success" // success, warning, error
          };
        })
        .catch(err => {
          this.title_subject = "";
          this.message_subject = "";
          this.$store.commit("SET_ALERT_STATE", true);
          this.alert = {
            message: err.response.data.message,
            type: "error" // success, warning, error
          };
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.commit("SET_ALERT_STATE", false);
          }, 3000);
        });
    },
  }
};
</script>

<style scoped lang="scss">
.child-element {
  height: 32vh;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>

<template>
    <div v-if="participant != null">
        <span class="red--text" v-if="participant.is_orphan" title="This participant exists only in the Atlas Registry">
          <v-icon color="red" small>mdi-alert</v-icon> This participant is stored in the Atlas Registry but not in the Atlas Participants Portal
        </span>
        <span v-else>
          <span 
            v-if="participant.participant_status_id == participantStatuses.PENDING"
            class="grey--text"
          >
            <v-icon color="grey" small>mdi-clock-outline</v-icon>
            Pending Approval&hellip;
          </span>
          <span 
            v-else-if="participant.participant_status_id == participantStatuses.APPROVED" 
            class="green--text"
          >
            <v-icon color="green" small>mdi-check</v-icon>
            Approved
          </span>
          <span 
            v-else-if="participant.participant_status_id == participantStatuses.REJECTED" 
            class="red--text"
          >
            <v-icon color="red" small>mdi-close-circle</v-icon>
            Rejected
          </span>        
        </span>
    </div>
</template>

<script>
export default {
  name: "ParticipantStatus",
  props: ['participant'],

  data() {
    return {  
      participantStatuses: {
        PENDING: 1,
        APPROVED: 2,
        REJECTED: 3,
        SUSPENDED: 4
      },            
    };
  },
}
</script>
import axios from "axios";
const getDefaultState = () => {
  return {
    status: "",
    token: localStorage.getItem("vue-token") || "",
    user: {}
  };
};

export const auth = {
  state: getDefaultState,
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    }
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("atlas_token");
        localStorage.removeItem("atlas_type");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    createParticipant({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: "/participants",
          data: user,
          method: "POST"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {
  }
};

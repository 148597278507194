<template>
  <v-container class=" px-lg-12 mt-5">
    <v-row>
      <v-col cols="12" lg="9" md="10">
        <p class="background_text pt-0 mt-0 " v-if="!sideMenuPermant">
          Create Service
        </p>

        <!-- Service Form -->
        <v-container :class="{ services_grid: !sideMenuPermant }">
          <v-row>
            <v-form ref="form" v-model="valid">
              <v-snackbar v-model="error_snackbar" top color="red">
                {{ text }}
              </v-snackbar>
              <v-col cols="12" sm="12" xs="12" lg="10" xl="10" md="12">
                <v-card class="pa-4 shadow-box">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Service name"
                          v-model="service.name"
                          :rules="nameRules"
                          dense
                          required
                          color="secondary"
                        ></v-text-field>
                        <v-textarea
                          label="Service description"
                          v-model="service.description"
                          :rules="descriptionRules"
                          required
                          color="secondary"
                        ></v-textarea>
                        <v-text-field
                          class="mt-4"
                          label="Website URL (optional)"
                          v-model="service.website_url"
                          color="secondary"
                          :rules="[genericRules.url]"
                          dense
                        ></v-text-field>
                        <v-text-field
                          class="mt-4"
                          label="Service Base URL"
                          v-model="service.service_base_url"
                          color="secondary"
                          :rules="[genericRules.required, genericRules.url]"
                          dense
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="OAuth2 Auth URL"
                          v-model="service.oauth2_auth_url"
                          :rules="[genericRules.required, genericRules.url]"
                          dense
                          required
                          color="secondary"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="OAuth2 Token URL"
                          v-model="service.oauth2_token_url"
                          :rules="[genericRules.required, genericRules.url]"
                          dense
                          required
                          color="secondary"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-text-field
                          label="OAuth2 Scopes - Space separated (optional)"
                          v-model="service.oauth2_scopes"
                          :rules="[]"
                          dense                          
                          color="secondary"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-radio-group v-model="auth_radio" mandatory>
                          <v-radio
                            label="The service supports the OAuth2 Dynamic Client Registration protocol"
                            :disabled="service.validator_client_id !== '' || service.validator_client_secret !== ''"
                            value="OAuth2"
                          ></v-radio>
                          <v-radio
                            label="Premade OAuth2 Client Credentials for the ATLAS Validator"
                            value="Client"
                            :disabled="service.oauth2_registration_url !== '' || service.oauth2_initial_registration_token !== ''"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col v-if="auth_radio === 'OAuth2'" cols="12" lg="6">
                        <v-text-field
                          label="OAuth2 client registration URL"
                          v-model="service.oauth2_registration_url"
                          :rules="[genericRules.required, genericRules.url]"
                          dense
                          color="secondary"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="auth_radio === 'OAuth2'" cols="12" lg="6">
                        <v-text-field
                          label="OAuth2 initial registration token"
                          v-model="service.oauth2_initial_registration_token"
                          dense
                          color="secondary"
                        ></v-text-field>
                      </v-col>

                      <v-col v-if="auth_radio === 'Client'" cols="12" lg="6">
                        <v-text-field
                          label="Client ID for Atlas Validator"
                          v-model="service.validator_client_id"
                          dense
                          color="secondary"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="auth_radio === 'Client'" cols="12" lg="6">
                        <v-text-field
                          label="Client Secret for Atlas Validator"
                          v-model="service.validator_client_secret"
                          dense
                          color="secondary"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <p
                          v-if="
                            service.oauth2_registration_url !== '' ||
                              service.validator_client_secret !== '' ||
                              service.validator_client_id !== '' ||
                              service.oauth2_initial_registration_token !==
                                ''
                          "
                        >
                          To change your selection please clear the fields
                          first
                        </p>
                        <v-select
                          v-model="select"
                          :items="specifications"
                          :rules="[
                            v =>
                              !!v.hasOwnProperty('core_api_id') ||
                              'Filed is required'
                          ]"
                          required
                          :item-text="item => item.name + ' - ' + item.majorMinorVersion"
                          item-value="id"
                          @change="getMandatoriSpeci()"
                          label="Service specification"
                          return-object
                        >
                        </v-select>

                        <p
                          class="font-weight-bold medium_text text-capitalize mb-0"
                        >
                          {{ select.description }}
                        </p>
                        
                        <!-- Specification Documents -->
                        <div class="mt-2">
                          <div v-if="select.hasOwnProperty('specifications_url') && select.specifications_url != null">
                              <v-icon small class="mr-1" style="vertical-align: baseline">mdi-open-in-new</v-icon>                                
                              <a :href="select.specifications_url" target="_blank">Specifications</a>
                          </div>
                          <div v-else>
                            <div v-if="select.hasOwnProperty('specification_document_url') && select.specification_document_url != null">
                              <a :href="select.specification_document_url" target="_blank">
                                Service Specification
                              </a>
                            </div>

                            <div v-if="select.hasOwnProperty('api_document_url') && select.api_document_url != null">
                              <a :href="select.api_document_url" target="_blank">
                                API Specification
                              </a>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="text-none btn_text shadow-box"
                      color="secondary"
                      @click="submit"
                    >
                      Create service
                    </v-btn>
                    <!--                    <a id="downloadAnchorElem" style="display:none"></a>-->
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <!-- Dialogs -->
    <v-dialog v-model="manifest_service" max-width="500" persistent>
      <v-card class="pa-6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" secondary--text text-wrap page_heading"
              >
                Service created</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card-text class="black--text">
          Service created successfully. You can now send your service for publication.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="text-none btn_text shadow-box"
            large
            @click="goTo()"
          >
            Go To Service
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { VersionManager, Version } from "../../utils/VersionManager"

export default {
  name: "GenerateService",
  data() {
    return {
      manifest_service: false,
      auth_radio: "OAuth2",
      settings: [],
      no_mandatory: [],
      endpoints: [],
      select: {},
      specifications: [],
      id: null,
      error_snackbar: false,
      text: "",
      errors: "",
      service: {
        name: "",
        description: "",
        website_url: "",
        service_base_url: "",
        oauth2_auth_url: "",
        oauth2_token_url: "",
        oauth2_scopes: "",
        oauth2_registration_url: "",
        oauth2_initial_registration_token: "",
        validator_client_id: "",
        validator_client_secret: "",
        service_specification_id: "",
      },

      valid: true,

      genericRules: {
        required: function(v) {
          return !!v || "This field is required";
        },

        url: function(v) {
          if (v == "") {
            return true;
          } else if (v !== null) {
            v = v + "";
            let parts = v.split(":");
            let whitespace = /\s/;
            if (parts.length == 2 && parts[0].length > 0 && parts[1].length > 0 && !whitespace.test(parts[0]) && !whitespace.test(parts[1])) {              
              return true;
            } 
          }
          return "Invalid URL";          
        },
      },

      nameRules: [
        v =>
          (v && v.length <= 100 && v.length >= 3) ||
          "Name must be between 3 to 100 characters ",
        v =>
          /(^[a-z]+[a-z0-9_.-]*$)+/.test(v) ||
          "Only lowercase letters and the following characters are allowed _ . -"
      ],

      descriptionRules: [
        v =>
          (v && v.length <= 5000) || "Description can't be more than 5000 characters "
      ],
    };
  },

  created() {
    this.getSpecifications();
  },
  computed: {
    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    }
  },
  methods: {
    getSpecifications() {
      this.$store.commit("SET_LOADER", true);
      this.$axios({
        method: "GET",
        url: "/specifications"
      })
        .then(res => {
          let specifications = [];
          let versionManager = new VersionManager();
          
          // Feed the version manager
          res.data.data.forEach(specification => {
            versionManager.add(specification.name, specification.version, specification.core_api_id);
            specification.majorMinorVersion = new Version(specification.version).getVersionText(2);
          })

          // Mark the deprecated versions
          res.data.data.forEach(specification => {
            if (versionManager.isDeprecated(specification.name, specification.version)) {
              specification.majorMinorVersion = specification.majorMinorVersion + " [Deprecated]";
            }

            specifications.push(specification);
          })

          this.specifications = specifications;
        })
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    },
    
    getMandatoriSpeci() {
      this.endpoints = [];
      this.service.service_specification_id = this.select.core_api_id;
      this.endpoints = this.select.endpoints.filter(c => {
        return c.mandatory === false;
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        return this.postService();
      }
    },

    postService() {
      this.createService();
    },

    createService() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$store.commit("SET_LOADER", true);
      const manifest = this.service;
      this.$axios({
        method: "post",
        url: "/services",
        data: manifest
      })
        .then(response => {
          this.id = response.data.data.id;
          this.manifest_service = true;
        })
        .catch(err => {
          this.error_snackbar = true;
          this.text = err.response.data.message;
        })
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    },

    generateManifest() {
      this.service.endpoints = [];
      this.endpoints.forEach(c => {
        if (c.check) {
          this.service.endpoints.push(c);
        }
      });
      let dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.service));
      let dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "manifest.json");
      dlAnchorElem.click();
      this.service = {
        name: "",
        description: "",
        service_base_url: "",
        auth: {
          oauth2_auth_url: "",
          oauth2_token_url: "",
          oauth2_registration_url: "",
          oauth2_initial_registration_token: "",
          validator_client_id: "",
          validator_client_secret: ""
        },

        service_specification_id: "",
        endpoints: []
      };
      this.manifest_service = true;
    },
    goTo() {
      this.$router.push({
        name: "Service",
        params: { id: this.id }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>

<template>
  <v-alert :type="type" dark class="mb-0" :value="getAlertState"      transition="scale-transition">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: "AlertBar",
  props: ["type", "message"],
  computed: {
    getAlertState() {
      return this.$store.getters.getAlertState;
    }
  },
};
</script>

<style scoped></style>

<template>
  <v-app>
    <v-main>
      <component :is="layout">
        <router-view />
      </component>
    </v-main>

    <v-dialog v-model="dialogState" width="700" persistent>
      <v-card class="pa-6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="secondary--text text-wrap page_heading">
                Welcome
              </v-list-item-title>
              <v-list-item-subtitle class="mt-4 text-wrap">
                To become an ATLAS participant you have to register your
                organization.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text>
          <one></one>
        </v-card-text>
      </v-card>
    </v-dialog>

    <OkDialog 
      :visible="showParticipantPendingDialog"
      v-on:ok="hideParticipantPendingDialog"
    >
      <template v-slot:title>
        {{ $t('participant_under_review_title') }}
      </template>
      <template v-slot:body>
        <p class="mt-2">
          <strong>
            Please provide the official supporting documents of your organization registration 
            at <a :href="`mailto:${registrationEmail}`">{{ registrationEmail }}</a>.
          </strong> 
          After 
          submitting the supporting documents your account will be reviewed and approved. Then 
          you will be able to submit and manage your services.
          In the meantime you will only be able to tweak you registration information.
        </p>
      </template>
    </OkDialog>

    <OkDialog 
      :visible="showParticipantRejectedDialog"
      v-on:ok="hideParticipantRejectedDialog"
    >
      <template v-slot:title>
        {{ $t('participant_rejected_title') }}
      </template>
      <template v-slot:body>
        <p class="mt-2">
          We are sorry to inform you that your application to become an Atlas
          participant was not accepted.
          <strong>
            Please check again and update your oranization's information 
            and/or the supporting documents and we will review your application again.
          </strong>
        </p>
      </template>
    </OkDialog>

    <v-overlay v-model="getloader" z-index="9999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import One from "@/components/boarding/One";
import OkDialog from "@/components/OkDialog";
import getEnv from "@/utils/env";

const default_layout = "default";

export default {
  name: "App",
  components: { One, OkDialog },

  data() {
    return {
      dialog: false,
      showParticipantPendingDialog: false,
      showParticipantRejectedDialog: false,
      participant_organization: "",
      text: "",
      userTypes: {
        ADMINISTRATOR: 1,
        PARTICIPANT: 2
      },
      participantStatuses: {
        PENDING: 1,
        APPROVED: 2,
        REJECTED: 3,
        SUSPENDED: 4
      }      
    };
  },

  created() {
    this.getProfile();
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },

    registrationEmail() {
      return getEnv("VUE_APP_DOCUMENTS_INSPECTOR_EMAIL");
    },

    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      getloader: "getloader",
      dialogState: "getDialog"
    })
  },

  methods: {
    getProfile() {
      this.$store.commit("SET_DIALOG", false);
      this.$axios.get("/users/me").then(res => {
        let user = res.data.data;
        this.$store.commit("set_user", user);

        if (
          user.user_type_id === this.userTypes.PARTICIPANT &&
          user.participant === null
        ) {
          this.$store.commit("SET_DIALOG", true);
          return;
        }

        if (
          user.user_type_id === this.userTypes.PARTICIPANT &&
          user.participant
        ) {
          console.log(user.participant.participant_status_id);
          if (user.participant.participant_status_id == this.participantStatuses.REJECTED) {
            this.showParticipantRejectedDialog = true;
            this.$router.replace({name: "Settings"});
          } else if (user.participant.participant_status_id != this.participantStatuses.APPROVED) {
            this.showParticipantPendingDialog = true;
            this.$router.replace({name: "Settings"});
          }
        }        
      });
    },

    hideParticipantPendingDialog() {
      this.showParticipantPendingDialog = false;
    },

    hideParticipantRejectedDialog() {
      this.showParticipantRejectedDialog = false;
    }
  }
};
</script>

<style lang="scss">
@import "assets/styles/global";

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.05s ease;
}

.component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

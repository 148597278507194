<template>
    <div v-if="service != null">
        <span 
          v-if="service.service_status_id == serviceStatuses.CREATED && service.validation_status !== false"
          class="grey--text"
        >
          <v-icon color="grey" small>mdi-note-outline</v-icon>
          Draft
        </span>
        <span 
          v-else-if="service.service_status_id == serviceStatuses.PENDING"
          class="grey--text"
        >
          <v-icon color="grey" small>mdi-clock-outline</v-icon>
          Pending Publication&hellip;
        </span>
        <span 
          v-else-if="service.service_status_id == serviceStatuses.APPROVED && service.validation_status !== true" 
          class="orange--text"
        >
          <v-icon color="orange" small>mdi-alert</v-icon>
          Validation Issues
        </span>
        <span 
          v-else-if="service.service_status_id == serviceStatuses.APPROVED" 
          class="green--text"
        >
          <v-icon color="green" small>mdi-check</v-icon>
          Published
        </span>
        <span 
          v-else-if="service.service_status_id == serviceStatuses.REJECTED || service.validation_status == false" 
          class="red--text"
        >
          <v-icon color="red" small>mdi-close-circle</v-icon>
          Failed
        </span>        
    </div>
</template>

<script>
export default {
  name: "ServiceStatus",
  props: ['service'],

  data() {
    return {  
      serviceStatuses: {
        CREATED: 0,
        PENDING: 1,
        APPROVED: 2,
        REJECTED: 3,
        SUSPENDED: 4
      },            
    };
  },
}
</script>
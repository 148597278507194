<template>
  <v-container class="pa-xl-8">
    <v-row>
      <v-col cols="12" lg="8" xl="5" md="12">
        <transition name="slide-fade" mode="out-in">
          <v-card class="pa-4 mt-6 elevation-0" v-if="!loadVendor" key="vendor">
            <v-card-text class="pb-2">
              <v-list-item three-line class="pb-0 mb-0">
                <v-list-item-avatar size="100">
                  <v-img
                    src="https://cdn.vuetifyjs.com/images/cards/store.jpg"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content></v-list-item-content>
                <v-list-item-avatar
                  size="10"
                  :color="
                    vendor.is_approved
                      ? '#63CA13'
                      : vendor.is_pending
                      ? '#939592'
                      : '#b71c1c'
                  "
                ></v-list-item-avatar>
              </v-list-item>
              <p class="btn_text black--text mt-4">
                <v-avatar tile size="20" class="mr-2"
                  ><img src="../../assets/mail.svg"
                /></v-avatar>
                {{ vendor.email }}
              </p>
              <p class="btn_text black--text">
                <v-avatar tile size="20" class="mr-2"
                  ><img src="../../assets/phone.svg"
                /></v-avatar>
                {{ vendor.phone }}
              </p>
              <div class="b-0">Legal Information</div>
              <p class="btn_text black--text mt-4">
                <v-avatar tile size="25" class="mr-2"
                  ><img src="../../assets/people.svg"
                /></v-avatar>
                {{ vendor.organization }}
              </p>
              <p class="btn_text black--text">
                <v-avatar tile size="25" class="mr-2"
                  ><img src="../../assets/vat.svg"
                /></v-avatar>
                {{ vendor.vat }}
              </p>
              <div class="my-4">Contact Information</div>
              <p class="btn_text black--text">
                <v-avatar tile size="20" class="mr-2"
                  ><img src="../../assets/contact.svg"
                /></v-avatar>
                {{ vendor.contact_person }}
              </p>
              <p class="btn_text black--text">
                <v-avatar tile size="20" class="mr-2"
                  ><img src="../../assets/country.svg"
                /></v-avatar>
                {{ vendor.country }} {{ vendor.city }}
              </p>
              <p class="btn_text black--text text-wrap">
                <v-avatar tile size="20" class="mr-2"
                  ><img src="../../assets/pin.svg"
                /></v-avatar>
                {{ vendor.state }} {{ vendor.street }}
              </p>

              <p class="btn_text black--text text-wrap">
                <v-avatar tile size="20" class="mr-2"
                  ><img src="../../assets/postal.svg"
                /></v-avatar>
                {{ vendor.postal_code }}
              </p>
            </v-card-text>
            <v-card-actions v-if="!vendor.is_rejected">
              <v-btn
                class=" btn_text text-none "
                rounded
                large
                color="error"
                text
                @click="(vendor_delete = true), (vendor_id = vendor.id)"
                >{{ $t("reject") }} Vendor</v-btn
              >
              <v-spacer></v-spacer>

              <v-btn
                color="secondary"
                class="btn_text text-none shadow"
                rounded
                large
                @click="approveVendor"
                v-if="!vendor.is_approved"
                >{{ $t("approve") }}</v-btn
              >
            </v-card-actions>
          </v-card>
          <v-skeleton-loader
            v-else
            key="skeleton"
            class=" mt-6 shadow"
            type="article, article, actions"
          ></v-skeleton-loader>
        </transition>
      </v-col>
    </v-row>
    <v-dialog v-model="vendor_delete" max-width="500" persistent>
      <v-card class="pa-2">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class=" primary--text text-wrap page_sub_heading "
              >
                {{ $t("reject") }} Vendor</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-avatar size="40">
              <v-btn icon @click="vendor_delete = false">
                <v-img
                  src="../../assets/close.svg"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-card-text class="black--text">
          {{ $t("reject_text") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            class="text-none btn_text mr-4 black--text"
            text
            @click="vendor_delete = false"
          >
            {{ $t("close") }}
          </v-btn>

          <v-btn
            class="text-none btn_text elevation-0"
            dark
            large
            color="error"
            @click="rejectVendor"
          >
            {{ $t("reject") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Vendor",
  data() {
    return {
      dialog: false,
      loadVendor: true,
      vendor_delete: false,
      vendor_id: "",
      select: [],
      capabilities: ["Programming", "Design", "Vue", "Vuetify"],
      vendor: {
        organization: "",
        vat: "",
        street: " ",
        city: "",
        postal_code: "",
        state: "",
        country: "",
        contact_person: "",
        phone: "",
        email: ""
      }
    };
  },
  created() {
    this.getVendor();
  },
  methods: {
    getVendor() {
      this.$axios
        .get(`/participants/${this.$route.params.id}`)
        .then(res => {
          this.vendor = res.data.data;
        })
        .finally(() => {
          this.loadVendor = false;
        });
    },
    approveVendor() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .patch(`/admin/participant/${this.$route.params.id}/approve`)
        .then(res => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message
          });
          this.$router.push({ name: "Vendors" });
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        });
    },
    rejectVendor() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .patch(`/admin/participant/${this.$route.params.id}/reject`)
        .then(res => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message
          });
          this.$router.push({ name: "Vendors" });
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
</style>

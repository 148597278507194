<template>
  <div>
    <v-container class="mt-15 px-lg-12">
      <v-row>
        <v-col cols="12" lg="12" md="12">
          <div class="d-lg-flex ">
            <div class=" mr-auto">
              <v-text-field
                flat
                rounded
                hide-details
                solo
                clearable
                @click:clear="clearVendors"
                :label="$t('search_vendors')"
                v-model="searchName"
                style="width: 500px"
              >
                <template slot="append">
                  <v-btn
                    fab
                    :dark="searchName !== ''"
                    :disabled="searchName === ''"
                    small
                    color="secondary"
                    class="mr-n4"
                    @click="getVendors"
                  >
                    <v-icon dark>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
            <div class="mt-5 mt-lg-0">
              <v-btn
                depressed
                class="mr-2"
                fab
                small
                dark
                color="primary"
                @click="filter_vendor_dialog = true"
              >
                <v-icon dark>mdi-filter</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="12" md="12">
          <span class="black--text font-weight-bold" v-if="filters.length > 0"
            >Filters:</span
          >
          <v-chip v-for="filter in filters" class="ma-2" :key="filter.id" label>
            {{ filter }}
          </v-chip>
        </v-col>
        <vuescroll :ops="ops">
          <div class="child-element">
            <v-col cols="12" lg="12" md="12" class=" mt-n5">
              <p class="background_text pt-0 mt-0 " v-if="!sideMenuPermant">
                {{ $t("vendors") }}
              </p>

              <v-container :class="{ services_grid: !sideMenuPermant }">
                <transition name="slide-fade" mode="out-in">
                  
                  <!-- Participant -->
                  <v-row v-if="!loadVendors" key="vendors">
                    <v-col
                      cols="12"
                      sm="12"
                      xs="12"
                      lg="4"
                      xl="3"
                      md="6"
                      v-for="(vendor, index) in vendors"
                      :key="index"
                    >
                      <v-hover v-slot:default="{ hover }">
                        <v-card
                          flat
                          class="px-4 shadow-box"
                          :class="{ shadow: hover, 'orphan-participant': vendor.is_orphan}"                          
                          @click="openVendor(vendor.id)"                        
                        >
                          <v-card-title>
                            <v-icon left>
                              mdi-bank
                            </v-icon>
                            {{ vendor.organization }}
                          </v-card-title>

                          <v-card-text>
                            {{ vendor.state }}, {{ vendor.country }} <br>
                            {{ vendor.contact_person }}
                          </v-card-text>

                          <v-divider></v-divider>

                          <!-- Status -->
                          <v-card-actions class="justify-end text-caption">
                            <ParticipantStatus :participant="vendor"/>
                          </v-card-actions>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>

                  <v-row v-else key="skeleton">
                    <v-col
                      cols="12"
                      sm="12"
                      xs="12"
                      lg="4"
                      xl="3"
                      md="6"
                      v-for="(vendor, index) in 10"
                      :key="index"
                    >
                      <v-skeleton-loader
                        class="shadow-box"
                        type="list-item-avatar-three-line"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </transition>
              </v-container>
            </v-col>
          </div>
        </vuescroll>
      </v-row>
    </v-container>

    <!-- Participant Details Dialog -->
    <v-dialog
      v-model="vendor_dialog"
      width="500"
      no-click-animation
      persistent
      :fullscreen="sideMenuPermant"
    >
      <v-card class="pa-4">
        <v-toolbar dense flat color="transparent">
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn light icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item>
                <v-btn
                  color="red"
                  class="btn_text text-none"
                  depressed
                  large
                  text
                  @click="participant_delete = true"
                  >{{ $t("delete") }}</v-btn
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer/>
          <v-btn 
            icon
            large
            @click="closeEdit()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- Organization Info -->
        <v-card-text>
          <vendor :vendor="vendor"></vendor>
        </v-card-text>
        <v-card-actions v-if="vendor.participant_status_id !== participantStatuses.APPROVED">
          <v-spacer></v-spacer>
          <v-btn
            v-if="vendor.participant_status_id !== participantStatuses.REJECTED"
            class=" btn_text text-none "
            rounded
            large
            color="error"
            text
            @click="rejectVendor"
            >{{ $t("reject") }}
          </v-btn>
          <v-btn
            color="secondary"
            class="btn_text text-none"
            depressed
            large
            text
            @click="approveVendor"
            >{{ $t("approve") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="participant_delete" max-width="500" persistent>
      <v-card class="pa-4">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class=" primary--text text-wrap page_heading">
                {{ $t("delete") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar tile size="20">
              <v-img
                @click="participant_delete = false"
                src="../../assets/close.svg"
                aspect-ratio="1"
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-card-text>
          {{ $t("delete_text") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            class="text-none btn_text mr-2 black--text"
            text
            @click="participant_delete = false"
          >
            {{ $t("close") }}
          </v-btn>

          <v-btn
            class="text-none btn_text elevation-0"
            dark
            large
            color="error"
            @click="deleteVendor"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="filter_vendor_dialog" width="500" persistent>
      <v-card>
        <v-card-title>
          Filters
        </v-card-title>

        <v-card-text>
          <p>Status</p>
          <v-checkbox
            v-for="tag in tags"
            :key="tag.id"
            :label="tag.title"
            :value="tag"
            v-model="status"
            multiple
            hide-details
          ></v-checkbox>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn_text text-none elevation-0"
            color="grey"
            text
            @click="filter_vendor_dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            class="btn_text text-none elevation-0"
            color="secondary"
            @click="getVendors"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vuescroll from "vuescroll";
import Vendor from "@/components/Vendor";
import ParticipantStatus from "@/components/ParticipantStatus";

export default {
  name: "Vendors",
  components: {
    Vendor,
    ParticipantStatus,
    vuescroll
  },
  data() {
    return {
      searchName: "",
      participant_delete: false,
      filters: [],
      id: null,
      vendor: {},
      filter_vendor_dialog: false,
      vendor_dialog: false,
      vendor_delete: false,
      loadVendors: true,
      status: [],
      ops: {
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: false,
          keepShow: false,
          background: "#44636A",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          size: "6px",
          disable: false
        }
      },
      readMoreActivated: false,
      select: [],
      vendors: [],
      tags: [
        { id: 1, title: "Pending", color: "#939592" },
        { id: 2, title: "Approved", color: "#63CA13" },
        { id: 3, title: "Rejected", color: "#b71c1c" }
      ],
      participantStatuses: {
        PENDING: 1,
        APPROVED: 2,
        REJECTED: 3,
        SUSPENDED: 4
      }, 
    };
  },
  created() {
    this.getVendors();
  },
  computed: {
    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    }
  },
  methods: {
    clearVendors() {
      this.loadVendors = true;
      const status = this.status.map(c => c.id);
      this.searchName = "";
      this.$axios
        .get(`/participants`, {
          params: { filter: this.searchName, status: status }
        })
        .then(res => {
          this.vendors = res.data;
        })
        .finally(() => {
          this.loadVendors = false;
        });
    },

    getVendors() {
      this.loadVendors = true;
      const status = this.status.map(c => c.id);

      if (this.searchName === null || this.searchName === undefined) {
        this.searchName = "";
        this.$axios
          .get(`/participants?`, {
            params: { filter: this.searchName, status: status }
          })
          .then(res => {
            this.vendors = res.data.data;
            this.filters = this.status.map(c => c.title);
          })
          .finally(() => {
            this.filter_vendor_dialog = false;
            this.loadVendors = false;
          });
      } else {
        this.$axios
          .get(`/participants?`, {
            params: { filter: this.searchName, status: status }
          })
          .then(res => {
            this.vendors = res.data.data;
            this.filters = this.status.map(c => c.title);
          })
          .finally(() => {
            this.filter_vendor_dialog = false;
            this.loadVendors = false;
          });
      }
    },

    openVendor(id) {
      this.getVendor(id);
    },

    getVendor(id) {
      this.$axios
        .get(`/participants/${id}`)
        .then(res => {
          this.vendor = res.data;
        })
        .finally(() => {
          this.vendor_dialog = true;
        });
    },

    approveVendor() {
      this.$store.commit("SET_LOADER", true);
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .patch(`/admin/participant/${this.vendor.id}/approve`)
        .then(res => {
          this.$store.commit("SET_LOADER", false);
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message
          });
          this.vendor_dialog = false;
          this.getVendors();
        })
        .catch(err => {
          this.$store.commit("SET_LOADER", false);
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        });
    },

    rejectVendor() {
      this.$store.commit("SET_LOADER", true);
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .patch(`/admin/participant/${this.vendor.id}/reject`)
        .then(res => {
          this.$store.commit("SET_LOADER", false);
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message
          });
          this.vendor_dialog = false;
          this.getVendors();
        })
        .catch(err => {
          this.$store.commit("SET_LOADER", false);
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        })
        .finally(() => {
          this.vendor_delete = false;
        });
    },

    deleteVendor() {
      this.$store.commit("SET_LOADER", true);
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .delete(`/participants/${this.vendor.id}`)
        .then(() => {
          this.vendor_dialog = false;
          this.participant_delete = false;
          this.getVendors();
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message,
            color: "primary"
          });
        })
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    },
    
    closeEdit() {
      this.vendor_dialog = false;
    }
  }
};
</script>
<style scoped lang="scss">
.child-element {
  height: 75vh;
}

.orphan-participant {
  background-color: rgba(235, 126, 97, .25)
}
</style>

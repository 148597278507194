<template>
  <div>
    <v-container class="mt-15 px-lg-12 ">
      <v-row>
        <v-col cols="12" lg="12" md="12" class="pb-0 mb-0">
          <div class="d-lg-flex ">
            <span class=" mr-auto">
              <v-text-field
                flat
                rounded
                hide-details
                solo
                clearable
                @click:clear="clearService"
                :label="$t('find_service')"
                class="shrink grow-shrink-0"
                v-model="serviceName"
                style="width: 500px"
              >
                <template slot="append">
                  <v-btn
                    fab
                    :dark="serviceName !== ''"
                    :disabled="serviceName === ''"
                    small
                    color="secondary"
                    class="mr-n4"
                    @click="getServices"
                  >
                    <v-icon dark>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </span>
            <span>
              <v-btn
                depressed
                class="mr-2 hidden-md-and-down"
                fab
                small
                dark
                color="primary"
                @click="filter_dialog = true"
              >
                <v-icon dark>mdi-filter</v-icon>
              </v-btn>
            </span>
            <span>
              <v-btn
                fab
                color="secondary"
                class="hidden-md-and-up"
                bottom
                right
                fixed
                @click="filter_dialog = true"
              >
                <v-icon>mdi-filter</v-icon>
              </v-btn>
              <v-btn
                depressed
                fab
                small
                dark
                color="secondary"
                v-if="!sideMenuPermant"
                @click="gridView = !gridView"
              >
                <v-icon dark>{{
                  gridView
                    ? "mdi-view-module"
                    : "mdi-format-list-bulleted-square"
                }}</v-icon>
              </v-btn>
            </span>
          </div>
        </v-col>
        <v-col cols="12" lg="12" md="12">
          <span class="black--text font-weight-bold" v-if="filters.length > 0"
            >Filters:</span
          >
          <v-chip v-for="filter in filters" class="ma-2" :key="filter.id" label>
            {{ filter }}
          </v-chip>
        </v-col>
        <v-col cols="12" lg="12" md="12" class="pt-0">
          <v-col cols="12" lg="12" md="10" class="mt-n8">
            <p class="background_text pt-0 mt-0 " v-if="!sideMenuPermant">
              {{ $t("services") }}
            </p>

            <v-container
              :class="{ services_grid: !sideMenuPermant }"
              :fluid="sideMenuPermant"
            >
              <transition name="slide-fade" mode="out-in" v-if="!showSkeleton">
                <v-row v-if="gridView" key="grid">
                  <v-col
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="4"
                    xl="3"
                    md="6"
                    v-for="service in services"
                    :key="service.id"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        flat
                        class="px-4 shadow-box"
                        :class="{ shadow: hover }"
                        @click="goTo(service.id)"                        
                      >
                        <v-card-title>
                          <v-icon                            
                            left
                          >
                            mdi-apps
                          </v-icon>
                          {{ service.name }}
                        </v-card-title>

                        <v-card-text>
                          <span v-if="service.description.length > 256">
                            {{ service.description.slice(0, 256) }} &hellip;
                          </span>
                          <span v-else>
                            {{ service.description }}
                          </span>                         
                        </v-card-text>

                        <v-divider></v-divider>

                        <!-- Service Status -->
                        <v-card-actions class="justify-end text-caption">
                          <ServiceStatus :service="service"/>
                        </v-card-actions>

                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>

                <v-row v-else key="table">
                  <v-col cols="12" sm="12" xs="12" lg="12" xl="12" md="12">
                    <v-data-table
                      :headers="headers"
                      :items="services"
                      :items-per-page="10"
                      class="shadow-box"
                    >
                      <template v-slot:item.status="{ item }">
                        <!-- <v-chip
                          dark
                          small
                          :ripple="false"
                          :color="statusColor(item.service_status_id).color"
                        >
                          {{ statusColor(item.service_status_id).title }}
                        </v-chip> -->
                        <ServiceStatus :service="item"/>
                      </template>

                      <template v-slot:item.specifications="{ item }">
                        <v-chip class="text--primary ">
                          {{ item.specification.name }}-{{
                            item.specification.version
                          }}
                        </v-chip>
                      </template>

                      <template v-slot:item.view="{ item }">
                        <v-btn
                          color="secondary"
                          depressed
                          class="btn_text text-none shadow"
                          @click="goTo(item.id)"
                          >{{ $t("view") }}</v-btn
                        >
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </transition>

              <transition name="slide-fade" mode="out-in" v-else>
                <v-row key="skeleton">
                  <v-col
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="4"
                    xl="3"
                    md="6"
                    v-for="(vendor, index) in 10"
                    :key="index"
                  >
                    <v-skeleton-loader
                      class="shadow-box"
                      type="list-item-avatar-three-line"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </transition>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="filter_dialog" width="500" persistent>
      <v-card>
        <v-card-title>
          Filters
        </v-card-title>

        <v-card-text>
          <p>Status</p>
          <v-checkbox
            v-for="tag in tags"
            :key="tag.id"
            :label="tag.title"
            :value="tag"
            v-model="status"
            multiple
            hide-details
          ></v-checkbox>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn_text text-none elevation-0"
            color="grey"
            text
            @click="filter_dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            class="btn_text text-none elevation-0"
            color="secondary"
            @click="getServices"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ServiceStatus from "@/components/ServiceStatus";

export default {  
  name: "Home",
  components: { ServiceStatus },

  data() {
    return {
      readMoreActivated: false,
      filter_dialog: false,
      gridView: true,
      showSkeleton: true,
      select: [],
      serviceName: "",
      filters: [],
      status: [],
      tag: {},
      headers: [
        { 
          text: this.$t("name"), 
          value: "name", 
          align: "center" },
        {
          text: this.$t("description"),
          align: "center",
          sortable: false,
          value: "description"
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: false,
          value: "status"
        },
        { text: "", value: "view", align: "center" }
      ],
      services: [],
    };
  },
  created() {
    this.$store.dispatch("get_notifications");
    this.$store.commit("SET_SNACKBAR", { state: false });
    this.getServices();
  },

  computed: {
    tags() {
      if (localStorage.getItem("atlas_type") === "1") {
        return [
          { id: 1, title: "Pending", color: "#ff9922" },
          { id: 2, title: "Approved", color: "#008844" }
        ];
      }
      return [
        { id: 0, title: "Draft", color: "#C0C0C0" },
        { id: 1, title: "Pending", color: "#ff9922" },
        { id: 2, title: "Approved", color: "#008844" }
      ];
    },
    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    }
  },
  methods: {
    clearService() {
      this.showSkeleton = true;
      const status = this.status.map(c => c.id);
      this.serviceName = "";
      this.$axios
        .get(`/services?filter=${this.serviceName}&status=${status}`)
        .then(res => {
          this.services = res.data.data;
        })
        .finally(() => {
          this.showSkeleton = false;
        });
    },
    getServices() {
      this.showSkeleton = true;
      const status = this.status.map(c => c.id);
      if (this.serviceName === null || this.serviceName === undefined) {
        this.serviceName = "";
        this.$axios
          .get(`/services?filter=${this.serviceName}&status=${status}`)
          .then(res => {
            this.services = res.data.data;
            this.filters = this.status.map(c => c.title);
          })
          .finally(() => {
            this.filter_dialog = false;
            this.showSkeleton = false;
          });
      } else {
        this.showSkeleton = true;
        this.$axios
          .get(`/services?filter=${this.serviceName}&status=${status}`)
          .then(res => {
            this.services = res.data.data;
            this.filters = this.status.map(c => c.title);
          })
          .finally(() => {
            this.filter_dialog = false;
            this.showSkeleton = false;
          });
      }
    },


    statusColor(status_id) {
      if (status_id === 0)
        return (this.tag = { id: 0, title: "Draft", color: "#000" });
      if (status_id === 1)
        return (this.tag = {
          id: 1,
          title: "Pending to be approved",
          color: "#ff9922"
        });
      if (status_id === 2)
        return (this.tag = {
          id: 2,
          title: "Approved by admin",
          color: "#008844"
        });
      if (status_id === 3)
        return (this.tag = {
          id: 3,
          title: "Rejected by admin",
          color: "#cc0000"
        });
      return "";
    },
    goTo(id) {
      this.$router.push({ name: "Service", params: { id } });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

import axios from "axios";
const getDefaultState = () => {
  return {
    notifications: [],
    notifyValue: 0
  };
};

export const notifications = {
  state: getDefaultState,
  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload.data;
    },
    SET_NOTIFICATIONS_LENGTH(state, payload) {
      state.notifyValue = payload;
    }
  },
  actions: {
    get_notifications({ commit }) {
      return new Promise((resolve, reject) => {

        axios({
          url: "/notifications",
          method: "GET"

        })
          .then(resp => {
            commit("SET_NOTIFICATIONS", resp.data);
            commit("SET_NOTIFICATIONS_LENGTH", resp.data.data.length);
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    remove_notifications({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/notifications/${id}/mark-dismissed`,
          method: "PATCH"
        })
          .then(resp => {
            // commit("SET_NOTIFICATIONS", resp.data);
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {
    getNotifications: state => state.notifications,
    getNotificationslength: state => state.notifyValue
  }
};

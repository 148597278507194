<template>
  <v-container :class="!sideMenuPermant ? 'px-12' : 'px-0'" class="mt-4">
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" lg="8" xl="6" md="12">                      
          <transition name="slide-fade" mode="out-in">
            <v-card
              class="pa-8 elevation-0"
              key="card"
              v-if="getSettings"
              color="transparent"
            >
              <form @submit.prevent="updateParticipant">
                <v-card-title class="secondary--text page_heading pb-1">
                  Organization
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="myprofile.credentials !== null"
                    class="btn_text text-none "
                    color="primary"
                    @click="openCred = true"
                    >View credentials</v-btn
                  >
                </v-card-title>
                <v-card-subtitle class="mt-1 mb-3">
                  {{ $t("all_fields_required") }}
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        id="organization"
                        class="btn_text"
                        :label="$t('organization')"
                        name="organization"
                        v-model.trim="myprofile.organization"
                        type="text"
                        :rules="organizationRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        class="btn_text"
                        id="vat"
                        :label="$t('vat')"
                        name="vat"
                        type="text"
                        v-model="myprofile.vat"
                        :rules="vatRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        class="btn_text"
                        id="city"
                        v-model="myprofile.city"
                        :label="$t('city')"
                        name="city"
                        type="text"
                        :rules="cityRules"
                        dense
                        required
                      ></v-text-field>
                      <v-text-field
                        class="btn_text mt-4"
                        id="postal_code"
                        :label="$t('postal_code')"
                        name="postal_code"
                        v-model="myprofile.postal_code"
                        type="text"
                        :rules="postal_codeRules"
                        dense
                        required
                      ></v-text-field>
                      <v-text-field
                        class="btn_text mt-4"
                        id="state"
                        :label="$t('state')"
                        name="state"
                        v-model="myprofile.state"
                        type="text"
                        :rules="stateRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        class="btn_text"
                        id="street"
                        :label="$t('street')"
                        v-model="myprofile.street"
                        name="street"
                        type="text"
                        :rules="streetRules"
                        dense
                        required
                      ></v-text-field>
                      <v-text-field
                        class="btn_text mt-4"
                        id="country"
                        :label="$t('country')"
                        v-model="myprofile.country"
                        name="country"
                        type="text"
                        :rules="countryRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        class="btn_text"
                        id="contact_person"
                        v-model="myprofile.contact_person"
                        :label="$t('contact_person')"
                        name="contact_person"
                        type="text"
                        :rules="contact_personRules"
                        dense
                        required
                      ></v-text-field>
                      <v-text-field
                        class="btn_text mt-4"
                        id="email"
                        v-model="myprofile.email"
                        :label="$t('email')"
                        name="email"
                        type="email"
                        :rules="emailRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        class="btn_text"
                        id="phone"
                        v-model="myprofile.phone"
                        :label="$t('phone')"
                        name="phone"
                        type="text"
                        :rules="phoneRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="error"
                    text
                    rounded
                    large
                    class="btn_text text-none "
                    @click="participant_delete_dialog = true"
                  >
                    {{ $t("delete_account_button") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="text-none   elevation-0 btn_text "
                    color="secondary "
                    large
                    @click="validate"
                    :disabled="!valid"
                  >
                    Update</v-btn
                  >
                </v-card-actions>
              </form>
            </v-card>
            <v-skeleton-loader
              key="skeleton"
              ref="skeleton"
              v-else
              type="article"
              class="mx-auto shadow-box mt-12"
            ></v-skeleton-loader>
          </transition>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="participant_delete_dialog" max-width="500" persistent>
      <v-card class="pa-4">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class=" primary--text text-wrap page_heading">
                {{ $t("remove_warning") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar tile size="20">
              <v-img
                  @click="participant_delete_dialog = false"
                  src="../../assets/close.svg"
                  aspect-ratio="1"
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-card-text>
          <p>
            <span v-html="$t('delete_account_text')"></span>
            &quot;<span class="secondary--text font-weight-bold">{{ myprofile.organization }}</span>&quot;
          </p>
          <v-text-field 
            label="Organization"
            v-model="deleteConfirmationText"
          >
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            class="text-none btn_text mr-2 black--text"
            text
            @click="participant_delete_dialog = false"
          >
            {{ $t("close") }}
          </v-btn>

          <v-btn
            class="text-none btn_text elevation-0"
            dark
            large
            color="error"
            @click="deleteParticipant"
            :loading="participant_delete_pending"
            :disabled="!participant_delete_confirmation || participant_delete_pending"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openCred" width="700" persistent>
      <v-card class="pa-6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class=" secondary--text text-wrap page_heading"
                >Atlas API Credentials</v-list-item-title
              >
              <v-list-item-subtitle
                class="black--text text-wrap mt-3"
              >
                To access the Atlas API you will need an access token. The
                following OAuth2 credentials can be used to request an access
                token from the Atlas authentication server.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-container fluid v-if="myprofile.credentials">
          <v-row>
            <v-col cols="12">
              <p class="black--text font-weight-black mb-0">OAuth2 Token URL</p>
              <p class="mt-1">{{ myprofile.credentials.oauth2_token_url }}</p>
              <p class="black--text font-weight-black mb-0">Client Secret</p>
              <p>{{ myprofile.credentials.client_secret }}</p>
              <p class="black--text font-weight-black mb-0">Client ID</p>
              <p>{{ myprofile.credentials.client_id }}</p>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none   elevation-0 btn_text "
            color="secondary "
            large
            @click="openCred = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      myprofile: {},
      openCred: false,
      length: 0,
      
      participant_delete_dialog: false, 
      participant_delete_pending: false,     
      deleteConfirmationText: "",
      
      getSettings: false,

      valid: true,

      organizationRules: [
        v => !!v || "Organization is required",
        v =>
          (v && v.length <= 100 && v.length >= 3) ||
          "Organization must be between 3 to 100 characters "
      ],

      vatRules: [
        v => !!v || "Vat is required",
        v => (v && v.length <= 100) || "Vat must be max 100 characters "
      ],

      streetRules: [
        v => !!v || "Street is required",
        v => (v && v.length <= 200) || "Street must be max 200 characters "
      ],

      cityRules: [
        v => !!v || "City is required",
        v => (v && v.length <= 200) || "City must be max 200 characters "
      ],

      postal_codeRules: [
        v => !!v || "Postal code is required",
        v => (v && v.length <= 100) || "Postal code must be max 100 characters "
      ],

      stateRules: [
        v => !!v || "State code is required",
        v => (v && v.length <= 50) || "State must be max 50 characters "
      ],

      countryRules: [
        v => !!v || "Country is required",
        v => (v && v.length <= 50) || "Country must be max 50 characters "
      ],

      contact_personRules: [
        v => !!v || "Contact person is required",
        v =>
          (v && v.length <= 100) || "Contact person must be max 100 characters "
      ],

      phoneRules: [
        v => !!v || "Phone is required",
        v => (v && v.length <= 50) || "Phone must be max 50 characters "
      ],

      emailRules: [
        v => !!v || "Email is required",
        v => (v && v.length <= 100) || "Email must be max 100 characters ",
        v =>
          /^[\w-+_.]+@([\w-]+\.)+[\w][\w]+$/.test(v) || "Must be email format"
      ],

    };
  },

  created() {
    this.getParticipant();
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },

    sideMenuPermant() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return true;
      } else if (this.$vuetify.breakpoint.name === "md") {
        return false;
      } else {
        return false;
      }
    },

    participant_delete_confirmation() {
      if (this.myprofile.organization) {
        return this.deleteConfirmationText.trim() == this.myprofile.organization.trim()
      }
      return false;
    }
  },

  methods: {
    deleteParticipant() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.participant_delete_pending = true;
      this.$axios
        .delete("/participants/my-profile")
        .then(() => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: this.$t("delete_account_success")            
          });
          this.$store.dispatch("logout").then(() => {
            this.$router.push("/");
            this.participant_delete_pending = false;
            this.$keycloak.logout();
          });
        })
        .catch(err => {
          this.participant_delete_pending = false;
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        });
    },

    validate() {
      if (this.$refs.form.validate()) {
        return this.updateParticipant();
      }
    },

    updateParticipant() {
      this.$store.commit("SET_LOADER", true);
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .patch("/participants/my-profile", this.myprofile)
        .then(() => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: "Organization Saved",
            color: "primary"
          });
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message,
            color: "primary"
          });
        })
        .finally(() => {
          this.$store.commit("SET_LOADER", false);
        });
    },

    getParticipant() {
      this.$axios
        .get(`/participants/my-profile`)
        .then(res => {
          this.myprofile = res.data.data;
          this.length = Object.keys(this.myprofile).length;
        })
        .finally(() => {
          this.getSettings = true;
        });
    }
  }
};
</script>

<style>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  color: #aaaaaa !important;  
  background-color: #dddddd !important;  
}
</style>

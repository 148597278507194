import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import { global } from "./global";
import { auth } from "./auth";
import { notifications } from "./notifications";
import { service } from "./service";
import { user } from "./user";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    global,    
    auth,
    user,
    notifications,
    service
  }
});

const getDefaultState = () => {
  return {
    user: {}
  };
};

export const user = {
  state: getDefaultState,
  mutations: {
    set_user(state, user) {
      state.user = user;
    },
  },
  actions: {},
  getters: {
    getUser: state => state.user
  }
};

<template>
  <div>
    <p
      class="primary--text text-h4 mb-2"
      style="font-size: 18px"
    >
      {{ vendor.organization }}
    </p>    
    <p class=" mb-0 font-weight-bold" style="font-size: 12px">
      Address
    </p>
    <p class="mb-0 pb-0 black--text" style="font-size: 14px">
      {{ vendor.street }},{{vendor.city}},{{vendor.state}},{{vendor.country}}
    </p>
    <p class="  black--text" style="font-size: 14px">
      {{vendor.postal_code}}
    </p>

    <p class=" mb-0 font-weight-bold" style="font-size: 12px">
      Contact person
    </p>
    <p class="mb-0 pb-0 black--text" style="font-size: 14px">
      {{ vendor.contact_person }}
    </p>
    <p class=" mb-0 black--text" style="font-size: 14px">
      {{ vendor.email }}
    </p>
    <p class="black--text" style="font-size: 14px">
      {{ vendor.phone }}
    </p>
    <p class=" mb-0 font-weight-bold" style="font-size: 12px">
      Organization VAT
    </p>
    <p class="mb-0 pb-0 black--text" style="font-size: 14px">
      {{ vendor.vat }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Vendor",
  props: {
    vendor: {}
  },
  data() {
    return {
      dialog: false,
      loadVendor: true,
      vendor_delete: false,
      vendor_id: ""
    };
  },

  methods: {
    approveVendor() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .patch(`/admin/participant/${this.vendor.id}/approve`)
        .then(res => {
          this.vendor.is_approved = true;
          this.vendor.is_rejected = false;
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message
          });
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        });
    },
    rejectVendor() {
      this.$store.commit("SET_SNACKBAR", { state: false });
      this.$axios
        .patch(`/admin/participant/${this.vendor.id}/reject`)
        .then(res => {
          this.vendor.is_approved = false;
          this.vendor.is_rejected = true;
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: res.data.message
          });
        })
        .catch(err => {
          this.$store.commit("SET_SNACKBAR", {
            state: true,
            text: err.response.data.message
          });
        })
        .finally(() => {
          this.vendor_delete = false;
        });
    }
  }
};
</script>
<style scoped lang="scss">
</style>
